// import { yupResolver } from "@hookform/resolvers/yup";
// import Grid from "@mui/material/Grid";
// import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
// import { downloadFile, isAdmin } from "@ui/Utils/helper";
// import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { createGlobalStyle } from "styled-components";
import { useAuth } from "@ui/components/AuthProvider";

import {
  createRecord,
  getByCode,
  paginate,
  updateRecord,
  uploadFiles,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";

import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import enums from "helpers/enums";
import { MySection } from "@ui/MuiComponents/index";
import MatTable from "@ui/components/MatTable";

// const GlobalStyle = createGlobalStyle`
//    body {
//     background: linear-gradient(
//     180deg,
//     rgba(255, 88, 88, 0.1) 0%,
//     rgba(123, 166, 229, 0.31) 69.79%
//   );
//     font-family: 'Advent Pro', sans-serif;
//     min-height: 100vh;
//     padding-inline: 2vh;
//     overflow: auto;
//   }

// `;

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {
  name: "",
};

const AppModuleDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();

  const params = useParams();

  const auth = useAuth();
  // const [clients, setClients] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code !== "create") {
          const res = await getByCode(enums.models.appModules, code);
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          // const res = await paginate(enums.models.clients);
          // setClients(res || []);
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models.appModules);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {};
      if (!values.code) {
        res = await createRecord(enums.models.appModules, values);
        navigate(`/${enums.models.appModules}/${res?.code}`);
      } else {
        res = await updateRecord(enums.models.appModules, values);
        navigate(`/${enums.models.appModules}/${res?.code}`);
      }
      formMethods.reset(res);
      setIsEditable(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate("/appModules");
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "textField",
    },
    {
      name: "key",
      label: "Key",
      fieldType: "textField",
    },
  ];

  const links = [
    {
      name: "Apps",
      url: "#/" + enums.models.appModules,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Header links={links} pageTitle={"App"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </FormProvider>
  );
};

export default AppModuleDetail;
